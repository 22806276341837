// $import '~antd/dist/antd.css';
// @import 'antd/dist/reset.css';
@import '../../assets/styles/variable.scss';

body {
    margin     : 0;
    color      : #333333;
    font-weight: 500;
    font-size  : 14px;
    font-family: 'Manrope', sans-serif !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow     : 0 0 0 30px white inset !important;
    /* Màu nền */
    -webkit-text-fill-color: #333 !important;
    /* Màu chữ */
}

/** LOGIN */
.row-form {
    display        : flex;
    height         : 100%;
    width          : 100%;
    flex-wrap      : wrap;
    align-items    : center;
    justify-content: center;
    align-self     : center;
    justify-items  : center;
    justify-self   : center;
    margin         : auto;

    &.row-login {
        height: 100vh;
    }
}

.col-form {
    background-color: $white;
    padding         : 30px 20px;
    padding-bottom  : 25px;
    // margin: 0 25px;
    border-radius   : 3px;
    position        : relative;

    a {
        &:hover {
            color: $medium;
        }
    }

    .form-btn {
        color           : $white;
        border-radius   : 5px;
        background-color: $primary-color;
        border-color    : $primary-color;
        height          : 40px;
        font-weight     : 600;
        font-size       : 14px !important;

        &:hover,
        &:focus,
        &:hover {
            background-color: $primary-light !important;
            border-color    : $primary-light;
        }
    }

    .ant-form {
        .ant-form-item {
            color        : $black;
            font-size    : 15px !important;
            margin-bottom: 15px;

            .ant-input {
                font-size    : 15px !important;
                padding      : 4px 15px;
                height       : 45px;
                border-radius: 5px;
                border       : 1px solid $gray100;

                &::placeholder {
                    color: $light_g;
                }

                &:hover,
                &:focus,
                &-focused {
                    box-shadow: unset;
                    border    : 1px solid $primary-color;
                }
            }

            .ant-input-affix-wrapper {
                border-radius: 5px;
                border       : 1px solid $gray100;
                padding      : 0 10px 0 0;

                .ant-input {
                    border: unset !important;
                }

                &:hover,
                &:focus,
                &-focused {
                    box-shadow: unset;
                    border    : 1px solid $primary-color;
                }
            }

            .ant-form-item-explain-error {
                margin-bottom: 10px;
                margin-top   : 5px;
                font-size    : 13px;
            }

            .ant-input-prefix {
                color      : $gray700;
                margin-left: 15px;
            }
        }
    }
}

.col-login {
    width        : 360px;
    box-shadow   : 0px 3px 4px 0px rgba(0, 0, 0, .03);
    border-radius: 0.75em;


    .div-form-title {
        p {
            margin-bottom: 1.5rem !important;
        }
    }
}

.opt-container input {
    background-color: #c0c7cc;
}