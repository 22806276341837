.tab-item-outline-2 {
    width   : 100%;
    overflow: hidden
}

.setting-kols {
    .input-dark {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            -webkit-box-shadow     : 0 0 0 30px #2b364f inset !important;
            -webkit-text-fill-color: white !important;
        }
    }
    .input-light {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            -webkit-box-shadow     : 0 0 0 30px white inset !important;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
        }
    }
}