@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

p {
  margin-bottom: 0 !important;
}

.buttonAntdPrimary {
  background-color: #1677ff;
}

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}

.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}

.MuiDataGrid-main {
  font-size: 14px;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root.MuiPickersDay-root {
  font-size: 14px
}

.MuiDateCalendar-root .MuiDayCalendar-header .MuiTypography-root.MuiDayCalendar-weekDayLabel {
  font-size: 14px !important
}